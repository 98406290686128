import { CloseIcon } from '@c/icons';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import Button from '@ui/Button';
import useNavigationEvent from '@util/hooks/useNavigationEvent';
import { cva } from 'class-variance-authority';
import { ChevronLeftIcon } from 'lucide-react';
import { Montserrat } from 'next/font/google';
import { Fragment, Suspense, useCallback } from 'react';

const montserrat = Montserrat({
  subsets: ['latin'],
  weight: ['400', '500', '600', '700'],
});

const styles = cva(
  'text-[1.5rem] flex flex-col relative  text-left text-brand-black shadow-xl transition-all max-h-screen',
  {
    variants: {
      isFullScreen: {
        true: 'w-full h-full',
        false: 'w-fit xl:max-w-[90%] min-w-[32rem] rounded-[3rem] mx-4',
      },
      overflowType: {
        auto: 'overflow-auto',
        visible: 'overflow-y-visible',
        hidden: 'overflow-hidden',
      },
      bgTransparent: {
        true: 'bg-black/70',
        false: 'bg-white',
      },
    },
  }
);
export interface BaseModalProps {
  isOpen: boolean;
  dismiss: () => void;
  children: React.ReactNode;
  title?: string;
  hideCloseIcon?: boolean;
  isFullScreen?: boolean;
  disableBackdropDismiss?: boolean;
  overflowType?: 'auto' | 'visible' | 'hidden';
  bgTransparent?: boolean;
  className?: string;
  backArrowOnClick?: () => void;
}

function BaseModal({
  children,
  title,
  hideCloseIcon,
  isOpen,
  dismiss,
  isFullScreen = false,
  disableBackdropDismiss = false,
  overflowType = 'auto',
  bgTransparent = false,
  className,
  backArrowOnClick,
}: BaseModalProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className={`relative z-[70] ${className}`}
        onClose={() => dismiss?.()}
        static={disableBackdropDismiss}
      >
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          {disableBackdropDismiss ? (
            <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
          ) : (
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          )}
        </TransitionChild>

        <div
          className={`fixed inset-0 z-10 overflow-y-auto ${montserrat.className}`}
        >
          <div className="flex h-full items-center justify-center">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <DialogPanel
                className={styles({
                  isFullScreen,
                  overflowType,
                  bgTransparent,
                })}
              >
                <div className={`relative flex w-full items-center sm:mt-0`}>
                  {backArrowOnClick && (
                    <Button
                      type="text"
                      leadingIcon={<ChevronLeftIcon />}
                      width="small"
                      onClick={backArrowOnClick}
                    />
                  )}

                  <div className={`items-center justify-between`}>
                    {!!title && (
                      <DialogTitle
                        className={
                          'mx-auto w-[80%] whitespace-nowrap py-3 text-center text-h3 font-semibold text-black '
                        }
                      >
                        {title}
                      </DialogTitle>
                    )}

                    {!hideCloseIcon && (
                      <Suspense>
                        <BaseModalCloseButton
                          dismiss={dismiss}
                          bgTransparent={bgTransparent}
                          isFullScreen={isFullScreen}
                        />
                      </Suspense>
                    )}
                  </div>

                  <div className="absolute bottom-0 left-0 right-0 h-px w-full bg-zinc-200" />
                </div>

                {children}
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

const BaseModalCloseButton = ({
  dismiss,
  bgTransparent,
  isFullScreen,
}: {
  dismiss: () => void;
  bgTransparent: boolean;
  isFullScreen: boolean;
}) => {
  useNavigationEvent(
    useCallback(({ url, previousUrl }) => {
      if (!url || !previousUrl) return;
      if (url !== previousUrl) {
        dismiss();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  );
  return (
    <button
      className={`absolute right-0 top-4 mx-10 ${
        bgTransparent ? 'text-white' : 'text-black'
      }`}
      type="button"
      onClick={() => dismiss()}
    >
      <CloseIcon />
    </button>
  );
};

export default BaseModal;
