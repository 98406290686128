import { cva, VariantProps } from 'class-variance-authority';
import { CloseIcon } from '..';

interface CloseButtonProps extends VariantProps<typeof closeButtonStyles> {
  onClick?: () => void;
}

export const closeButtonStyles = cva(
  'transition-colors flex items-center justify-center w-[2.4rem] h-[2.4rem]'
);

const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <>
      <button onClick={onClick} className={closeButtonStyles()}>
        <CloseIcon width={20} height={20} />
      </button>
    </>
  );
};

export default CloseButton;
